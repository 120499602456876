<template>
  <div>
    <gmap-map
      :options="{ disableDoubleClickZoom: true, draggable: false, maxZoom: 13 }"
      :center="center"
      :zoom="13"
      :disableDoubleClickZoom="true"
      style="width:100%; height: 400px;"
    >
      <gmap-marker
        :position="marker.position"
        :icon="{ url: require('@/assets/marker.png')}"
      ></gmap-marker>
    </gmap-map>
  </div>
</template>

<script>

export default {
  name: 'ProductUbication',
  props: {
    lat: {
      type: Number
    },
    lng: {
      type: Number
    }
  },
  data () {
    return {
      center: { lat: 45.508, lng: -73.587 },
      marker: {}
    }
  },
  mounted () {
    this.center = { lat: this.lat, lng: this.lng }
    this.marker = {
      id: 'a',
      position: { lat: this.lat, lng: this.lng },
      title: 'Hello World!'
    }
  },
  beforeUpdate () {
    this.center = { lat: this.lat, lng: this.lng }
    this.marker = {
      id: 'a',
      position: { lat: this.lat, lng: this.lng },
      title: 'Hello World!'
    }
  },
  methods: {
  }
}
</script>

<style scoped>
  .google-map {
    width: 100%;
    min-height: 100%;
  }
</style>
